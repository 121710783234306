<template>
    <div class="donate relative">

        <div
            class="donate__cont w-m mx-auto relative bg-accent-light rounded-m overflow-hidden flex flex-wrap align-stretch"
        >
            <div
                class="donate__image w-12 l-up:w-5 bg-accent bg-cover"
                v-lazy:background-image="cdnurl(data.donate_image)"
                :title="data.donate_image_caption_en"
            />

            <div class="donate__form bg-accent-light rounded-m w-12 l-up:w-7 pl-xl pr-l py-xl">
                <slot />
                <template v-if="step === 'amount'">
                    <form @submit.prevent="onAmountSubmit" validate>
                        <IntroText
                            :title="data.donate_title_en"
                            :text="data.donate_text_en"
                        />

                        <!-- <div class="color-secondary weight-b text-center">{{ $t('donate.type_title') }}</div>
                        <div class="radio__group radio__group--horizontal shadow-m rounded-m">
                            <input type="radio" name="type" id="type_once" value="once" v-model="form.type" nocheckbox="true" required />
                            <label for="type_once">{{ $t('donate.type_single') }}</label>
                            <input type="radio" name="type" id="type_recurring" value="recurring" v-model="form.type" nocheckbox="true" required />
                            <label for="type_recurring">{{ $t('donate.type_recurring') }}</label>
                        </div> -->

                        <div class="color-secondary weight-b text-center mt-xl">
                            {{ $t('donate.type_title') }} <span class="text-lowercase">{{ $t('donate.type_single') }} {{ $t('donate.amount_title') }}</span>
                            <!-- {{ $t('donate.amount_title') }} -->
                        </div>
                        <div class="donate__options radio__group radio__group--horizontal flex-wrap align-stretch shadow-m rounded-m">
                            <template v-for="amount in donation_amounts">
                                <input
                                    :key="amount.id"
                                    type="radio"
                                    name="amount"
                                    :id="'amount_' + amount.id"
                                    :value="amount.amount"
                                    v-model="selectedAmount"
                                    nocheckbox="true"
                                    required
                                />
                                <label
                                    :for="'amount_' + amount.id"
                                    :key="'label_' + amount.id"
                                    class="flex-col"
                                >
                                    <div class="size-4 font-title weight-b">{{ amount.amount | currency }}</div>
                                    <div class="size-7 color-secondary">
                                        {{ amount.description_en }}
                                    </div>
                                </label>
                            </template>

                            <input
                                type="radio"
                                name="amount"
                                id="amount_other"
                                value="other"
                                v-model="selectedAmount"
                                nocheckbox="true"
                                required
                            />
                            <label
                                for="amount_other"
                                class="flex-col"
                            >
                                <div class="size-5 font-title">{{ $t('donate.amount_custom') }}</div>
                                <div class="size-7 color-secondary">
                                    {{ $t('donate.amount_custom_description') }}
                                </div>
                            </label>
                        </div>

                        <div v-if="selectedAmount === 'other'" class="mt-m">
                            <input
                                type="number"
                                min="1"
                                step="0.01"
                                :value="form.amount"
                                @change="sanitizeAmount"
                                :placeholder="$t('donate.amount_custom_description')"
                                required
                                ref="customAmount"
                            />
                        </div>

                        <div class="text-center mt-m">
                            <button type="submit" class="bg-accent">{{ $t('donate.next') }}</button>
                        </div>
                    </form>

                </template>

                <template v-else-if="step === 'details'">
                    <form @submit.prevent="onDetailsSubmit" validate>
                        <div class="flex align-center justify-between mb-r">
                            <div class="color-secondary weight-b">{{ $t('donate.details') }}</div>
                            <button type="button" class="color-accent -s" @click="onBackToAmount">{{ $t('donate.back') }}</button>
                        </div>
                        <fieldset>
                            <label for="name" class="align-end justify-between">
                                <div>{{ $t('donate.name') }}</div>
                                <span v-if="form.type === 'once'" class="size-7 color-tertiary">{{ $t('donate.optional') }}</span>
                            </label>
                            <input type="text" id="name" v-model="form.name" :required="form.type === 'recurring'" autocomplete="name" minlength="2" />
                        </fieldset>

                        <fieldset>
                            <label for="name" class="align-end justify-between">
                                <div>{{ $t('donate.email') }}</div>
                                <span v-if="form.type === 'once'" class="size-7 color-tertiary">{{ $t('donate.optional') }}</span>
                            </label>
                            <input type="email" id="name" v-model="form.email" :required="form.type === 'recurring'" autocomplete="email" />
                            <div v-if="form.type === 'once'" class="size-7 color-tertiary">
                                {{ $t('donate.email_description_optional') }}
                            </div>
                        </fieldset>

                        <div
                            class="rounded-m bg-white p-l text-center"
                            v-html="$t('donate.summary_text', {
                                type: form.type === 'once' ? $t('donate.type_single').toLowerCase() : $t('donate.type_recurring').toLowerCase(),
                                amount: $options.filters.currency(form.amount)
                            })"
                        />

                        <div class="text-center mt-m">
                            <button type="submit" class="bg-accent">{{ $t('donate.payment') }}</button>
                        </div>
                    </form>

                </template>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'DonateModule',
    data() {
        return {
            form: {
                amount: null,
                type: 'once',
                name: '',
                email: '',
            },
            selectedAmount: null,
            step: 'nope',
        };
    },
    methods: {
        setDefaultDonationAmount(val) {
            if (val && val.length) {
                this.selectedAmount = val[0].amount;
            }
        },

        setSelectedAmount(val) {
            if (typeof val === 'number') {
                this.form.amount = val;
            }
            if (val === 'other') {
                this.$nextTick(() => {
                    this.$refs.customAmount.select();
                });
            }
        },

        onAmountSubmit() {
            this.step = 'details';
        },

        onBackToAmount() {
            this.step = 'amount';
        },

        onDetailsSubmit() {
            const params = new URLSearchParams('action=payment');
            params.set('name', this.form.name);
            params.set('email', this.form.email);
            params.set('amount', this.form.amount);
            params.set('recurring', this.form.type === 'once');
            const url = `https://thegoodroll.nl/models/handlers/foundation_payments.php`;
            window.open(url + '?' + params.toString());
        },

        sanitizeAmount(event) {
            let value = event.target.value;
            const lastPart = value.substr(-3);
            const commaChar = lastPart.indexOf(',') > lastPart.indexOf('.') ? ',' : '.';
            if (commaChar === '.') {
                value = value.replace(',', ''); // remove all commas
            } else if (commaChar === ',') {
                value = value.replace('.', '').replace(',', '.');
            }

            value = Number(value);
            this.form.amount = value;
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        donation_amounts() {
            if (this.data
                && this.data.foundation_donation_amounts
                && this.data.foundation_donation_amounts[0]
                && this.data.foundation_donation_amounts[0].items
                && this.data.foundation_donation_amounts[0].items.length > 0
            ) {
                return this.data.foundation_donation_amounts[0].items;
            }
            return false;
        },
    },
    watch: {
        donation_amounts: 'setDefaultDonationAmount',
        selectedAmount: 'setSelectedAmount',
    },
};
</script>

<style>
    .donate__cont {
        border: 0.3rem solid var(--color-accent);
    }

    @media (max-width: 62em) {
        .donate__image { height: 0; padding-top: 40%; }
    }
    @media (max-width: 48em) {
        .donate__image { height: 0; padding-top: 50%; }
    }
    @media (max-width: 34em) {
        .donate__image { height: 0; padding-top: 56%; }
    }
    @media (min-width: 34em) {
        .donate__options.radio__group {
            border: 0.1rem solid var(--color-border);
            border-width: 0.1rem 0.2rem 0 0;
            border-radius: var(--radius-m);
        }
        .donate__options.radio__group input+label {
            flex-basis: 50%;
            border-radius: 0;
            border-width: 0 0 0.1rem 0.1rem !important;
            height: auto;
            padding: var(--size-r)
        }
        .donate__options.radio__group input+label:nth-child(2){
            border-radius: var(--radius-m) 0 0 0;
            /* border-left-width: 0.1rem;
            border-right-width: 0; */
        }
        .donate__options.radio__group input+label:nth-child(4){
            border-radius: 0 var(--radius-m) 0 0;
            border-left-width: 0;
        }
        .donate__options.radio__group input+label:nth-last-child(3){
            border-radius: 0 0 0 var(--radius-m);
            border-bottom-width: 0.1rem;
            /* border-right-width: 0;
            border-bottom-width: 0.1rem; */
        }
        .donate__options.radio__group input+label:nth-last-child(1){
            border-radius: 0 0 var(--radius-m) 0;
            /* border-left-width: 0.1rem;
            border-bottom-width: 0.1rem; */
        }
    }
</style>
