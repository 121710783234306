<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
        </WaveDownBG>
        <div class="c w-xs mt-xxl pt-xxl pb-m">

        </div>
        <DonateModule>
            <IntroText
                :title="data.donate_intro_title_en"
                :text="data.donate_intro_text_en"
                :actions="data.donate_intro_button_title_en && data.donate_intro_button_link ? [{
                    href: data.donate_intro_button_link,
                    class: 'color-accent shadow-m',
                    title: data.donate_intro_button_title_en
                    }] : []
                "
            />
        </DonateModule>

        <div class="spacer" />

        <Impact class="my-xxl" />

        <br />
    </div>
</template>

<script>
import Impact from '@/components/Impact.vue';
import WaveDownBG from '@/components/bgs/WaveDownSmall.vue';
import DonateModule from '@/components/Donate.vue';

export default {
    name: 'Donate',
    components: {
        Impact,
        WaveDownBG,
        DonateModule,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>

<style>
.spacer { height: 10vh; }
</style>
